import React from 'react';
import {
  IPosition,
  getMouseNormalizedDistanceToCenter,
  mousePosition,
  rootNode,
} from "../utils";
import logo from './logo_dark.svg';
import './index.css';

const MAX_ROTATION: number = 70; // in degres
const ROTATION_INTERVAL: number = 50; // in milliseconds

const Logo: React.FC = () => {
  return (
    <a
      title="contact"
      href="mailto:teddy@toussaint.pro"
    >
      <img
        id="logo-main"
        alt="logo"
        className="App-logo"
        src={logo}
      />
    </a>
  );
};

window.setInterval(() => {
  const logoElement = document.getElementById('logo-main');
  if (
    logoElement &&
    rootNode &&
    mousePosition.x > 0 &&
    mousePosition.y > 0
  ) {
    const centerPosition: IPosition = {
      x: rootNode.clientLeft + rootNode.clientWidth / 2,
      y: rootNode.clientTop + rootNode.clientHeight / 2,
    };

    const toCenterVector: IPosition = {
      x: centerPosition.x - mousePosition.x,
      y: centerPosition.y - mousePosition.y,
    };

    const perpendicularVector: IPosition = {
      x: -toCenterVector.y,
      y: toCenterVector.x,
    };


    const angle = `${getMouseNormalizedDistanceToCenter() * MAX_ROTATION}deg`;

    logoElement.style.transform = `rotate3d(${perpendicularVector.x},${perpendicularVector.y},0,${angle})`;
  }
}, ROTATION_INTERVAL);

window.addEventListener('mouseout', () => {
  const logoElement = document.getElementById('logo-main');
  if (logoElement) logoElement.style.transform = "none";
});

export default Logo;