import './index.css';
import {
  getMouseNormalizedDistanceToCenter,
  mousePosition,
  rootNode,
} from '../utils';

const GENERATION_INTERVAL: number = 0.25; // in seconds
const MAX_LIFE_TIME: number = 2; // in seconds

let ballNumber: number = 0;

function getRandomInt(min: number, max: number): number {
  return Math.round(Math.random() * (max - min + 1)) + min;
}

window.setInterval(() => {
  if (mousePosition.x > 0 && mousePosition.y > 0) {
    const id = `ball-${++ballNumber}`;
    const range = 15;
    const size = getRandomInt(10, 20);
    const redMaxValue = (1 - getMouseNormalizedDistanceToCenter()) * 255;

    const background = `rgb(${getRandomInt(0, redMaxValue)},0,0)`;
    const height = `${size}px`;
    const width = `${size}px`;
    const left = `${getRandomInt(mousePosition.x - range - size, mousePosition.x + range)}px`;
    const top = `${getRandomInt(mousePosition.y - range - size, mousePosition.y + range)}px`;
    const style: string = `background: ${background}; height: ${height}; width: ${width}; left: ${left}; top: ${top};`;

    const ball = document.createElement('div');
    ball.id = id;
    ball.className = 'ball';
    ball.style.cssText = style;

    const removeBall = () => ball.remove();
    ball.addEventListener("webkitAnimationEnd", removeBall);
    ball.addEventListener("mozAnimationEnd", removeBall);
    ball.addEventListener("MSAnimationEnd", removeBall);
    ball.addEventListener("oanimationend", removeBall);
    ball.addEventListener("animationend", removeBall);
    if (rootNode) {
      rootNode.appendChild(ball);
      window.setTimeout(removeBall, MAX_LIFE_TIME * 1000);
    }
  }
}, GENERATION_INTERVAL * 1000);
