import React from 'react';
import Logo from '../Logo';
import Lorem from "../Lorem";
import '../Balls';
import './index.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <Logo />
      <Lorem />
    </div>
  );
}

export default App;
