export const rootNode = document.getElementById('root');

export const mousePosition: IPosition = {
  x: -1,
  y: -1,
};

window.addEventListener('mousemove', (event: MouseEvent) => {
  mousePosition.x = event.pageX;
  mousePosition.y = event.pageY;
});

window.addEventListener('mouseout', () => {
  mousePosition.x = -1;
  mousePosition.y = -1;
});

export function getDistanceBetween<A extends IPosition, B extends IPosition>(pointA: A, pointB: B): number {
  let distance = 0;
  const hOffset = pointA.x - pointB.x;
  const vOffset = pointA.y - pointB.y;

  distance = Math.sqrt(Math.pow(hOffset, 2) + Math.pow(vOffset, 2));

  return distance;
}

export function getMouseNormalizedDistanceToCenter(): number {
  let normDistance = 1;

  if (rootNode) {
    const centerPosition: IPosition = {
      x: rootNode.clientLeft + rootNode.clientWidth / 2,
      y: rootNode.clientTop + rootNode.clientHeight / 2,
    };

    const maxDistance = getDistanceBetween(centerPosition, { x: 0, y: 0 });
    const distance = getDistanceBetween(centerPosition, mousePosition);
    if (maxDistance !== 0) normDistance = distance / maxDistance;
  }

  return normDistance;
}

export interface IPosition {
  x: number;
  y: number;
}
